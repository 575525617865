import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { attorneyData } from '../lawyer/lawyerData';
import { Attorney } from 'src/app/Interfaces/interfaces';

import { MatAccordion } from '@angular/material/expansion';

@Component({
  selector: 'app-lawyer-details',
  templateUrl: './lawyer-details.component.html',
  styleUrls: ['./lawyer-details.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AttorneyDetailsComponent implements OnInit {
  @ViewChild(MatAccordion) accordion: MatAccordion;

  lawyer: Attorney;
  lawyers: Attorney[];

  finalAttorneyId: number;
  currentRouteId: number;
  
  attorneyKeys: string[];
  attorneyKeysTitles: string[];
  nextAttorneyName: string;
  previousAttorneyName: string;

  constructor(private route: ActivatedRoute, private router: Router) {
    this.route.paramMap.subscribe((params) => {
      const id = +params.get('id');
      this.currentRouteId = id;
      this.lawyer = attorneyData[+this.route.snapshot.paramMap.get('id')];
    
      if (!this.lawyer) {
        this.router.navigate(['/meet-our-team']);
      }
      
      this.lawyers = attorneyData;

      this.nextAttorneyName = this.lawyers[id + 1]?.name;
      this.previousAttorneyName = this.lawyers[id - 1]?.name;
      this.finalAttorneyId = this.lawyers[this.lawyers.length - 1].id;

      this.getAttorneyKeys();
      this.getKeysTitles();
    });
  }

  ngOnInit(): void {

  }

  getAttorneyKeys() {
    this.attorneyKeys = Object.keys(this.lawyer).filter((key) => {
      if (this.lawyer[key] && (!(key !== "practiceAreas" && key !== "biography" && key !== "education" && key !== "admissions" && key !== "designation" && key !== "membership" && key !== "awards" && key !== "languages"))) {
        return key;
      }
    })
  }

  getKeysTitles() {
    let attorneyKeysCopy = [...this.attorneyKeys]
    attorneyKeysCopy.forEach((key, index) => {
      switch(key) {
        case 'practiceAreas':
          attorneyKeysCopy[index] = 'Areas of Practice';
        break;
        case 'admission':
          attorneyKeysCopy[index] = 'Admissions';
        break;
        default:
          const startWithUpperCase = key.charAt(0).toUpperCase() + key.slice(1);
          attorneyKeysCopy[index] = startWithUpperCase;
        break;
      }
    });
    this.attorneyKeysTitles = attorneyKeysCopy;
  }

  isString(key) : boolean { 
    return typeof key === 'string'; 
  }

  }


