<footer>
    <div class="container">
        <div class="copyright-area">
            <div class="row">
                <div style="display:flex; align-items: center;" class="col-sm-7 col-lg-6">
                    <img class="copyright-item" style="width:200px; height:auto;" src="/assets/img/theRepublicLawLogo.webp" />
                    <div class="copyright-item">
                        <!-- <p> © 2023 The Republic Law </p> -->
                    </div>
                </div>

                <div style="display:flex; align-items: center; justify-content: end;" class="col-sm-5 col-lg-6 footer-text">
                    <div class="copyright-item copyright-right">
                        <a routerLink="/terms-conditions" target="_blank">Terms & Conditions</a> <span>-</span>
                    </div>
                    <div class="copyright-item copyright-right">
                        © The Republic Law 2023. All Rights Reserved.
                    </div>
                </div>


                <div style="display:flex; align-items: center; flex-direction: column;" class="footer-responsive">
                    <div class="row copyright-item" style="justify-content: center;">
                        <a routerLink="/terms-conditions" target="_blank">Terms & Conditions</a>
                    </div>
                    <div class="copyright-item copyright-right row" style="justify-content: center;">
                        © The Republic Law 2023. All Rights Reserved.
                    </div>
                </div>



            </div>
        </div>
    </div>
</footer>