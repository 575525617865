<!-- background-size: 'cover', 
background-repeat: 'no-repeat', 
max-height: '600px', 
overflow: 'hidden', 
background-position: 'center' -->
<!-- <div [style.background]="'url(assets/img/' + practice.img + ')'" class="page-title-area-practices">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="page-title-text">
                <h2 style="padding-top: 80px;">{{practice.name}}</h2>
            </div>
        </div>
    </div>
</div> -->

<div class="page-title-area-practices" [ngStyle]="{'background-image': 'url(' + headerImage + ')'}">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="page-title-text">
                <h2 style="padding-top: 80px;">{{practice.name}}</h2>
            </div>
        </div>
    </div>
</div>
<div class="page-title-text-responsive">
    <h2 style="padding-top: 80px;">{{practice.name}}</h2>
</div>

<div class="practice-details-area">
    <div class="container">
        <div class="row">
            <!-- <div class="col-lg-8"> -->
                <div class="practice-details-item">
                    <div class="practice-details-content">
                        <div class="section-title text-start">
                            <h3>Overview</h3>
                        </div>
                        <p style="white-space: pre-wrap; padding-right: 20px;">{{practice.content}}</p>
                    </div>
                </div>

                <div class="practice-details-case">
                    <div class="row">
                    </div>
                </div>
            <!-- </div> -->
            <!-- <div class="col-lg-4">
                <div class="practice-details-item">
                    <div class="practice-details-contact">
                        <h3>Key Contacts</h3>
                        <div class="container">
                            <div class="lawyer-card row" style="padding: 15px; background-color: #ecececb4;">
                                <ng-container *ngFor="let lawyer of lawyers">
                                    <div *ngIf="lawyer.specialty === practice.name" class="col" style="display: flex; align-items: center;">
                                        <img srcset={{lawyer.img}} style="width: 200px;">
                                    </div>
                                    <div *ngIf="lawyer.specialty === practice.name" class="col" style="margin: 0 auto; padding-left: 5px;">
                                        <ul style="list-style: none; padding: 0; margin: 0;">
                                            <li><h5>{{ lawyer.name }}</h5></li>
                                            <li><h6>{{ lawyer.title }}</h6></li>
                                            <li><i class="flaticon-call"></i> <a href="tel:+07554332322">{{ lawyer.phone }}</a></li>
                                            <li><i class="flaticon-email"></i> <a href="mailto:hello@lufz.com">{{ lawyer.email }}</a></li>
                                        </ul>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->
            <div class="blog-details-category">
                <h3 style="color:#161618; padding-bottom: 10px;">Other Practices</h3>
                <ng-container *ngFor="let practice of practices">
                  <ul *ngIf="currentPageName !== practice.name">
                    <li>
                      <a [routerLink]="['/practices', practice.id, practice.name]">{{ practice.name }} <i class="icofont-arrow-right"></i></a>
                    </li>
                  </ul>
                </ng-container>
              </div>
        </div>
    </div>
</div>