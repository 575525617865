<div class="navbar-area fixed-top">

    <div class="mobile-nav">
        <a routerLink="/" class="logo">
            <img style=" height: auto;" srcset="/assets/img/theRepublicLawLogo.webp" alt="Logo">
        </a>
    </div>

    <div class="main-nav" style="display: flex; justify-content: center; flex-direction: column;">
        <div class="container">
            <nav class="navbar navbar-expand-md navbar-light">
                <a class="navbar-brand" routerLink="/">
                    <img style="width: 300px; height: 100%;" srcset="/assets/img/theRepublicLawLogo.webp" alt="Logo">
                </a>
                <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                    <ul class="navbar-nav">
                        <li class="nav-item"><a routerLink="/" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home</a></li>
                        <li class="nav-item"><a routerLink="/about" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">About</a></li>     
                        <li class="nav-item">
                            <a routerLink="/meet-our-team" class="nav-link">Meet Our Team</a>
                            <ul class="dropdown-menu">
                                <li *ngFor="let lawyer of lawyers;" class="nav-item" ><a [routerLink]="['/meet-our-team', lawyer.id, lawyer.name]" class="nav-link">{{lawyer.name}}</a></li>   
                            </ul>
                        </li> 
                         <li class="nav-item">
                            <a routerLink="/practices" class="nav-link">Practice Areas</a>
                            <ul class="dropdown-menu">
                                <li *ngFor="let practice of practices;" class="nav-item" ><a [routerLink]="['/practices', practice.id, practice.name]" class="nav-link">{{practice.name}}</a></li>   
                            </ul>
                        </li>
                        
                        <li class="nav-item">
                            <a class="nav-link">Knowledge</a>
                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/" class="nav-link" >Coming Soon!</a></li>
                                <!-- <li class="nav-item"><a routerLink="/blog" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Blogs</a></li>
                                    <li class="nav-item"><a routerLink="/" class="nav-link">Publications</a></li>
                                    <li class="nav-item"><a routerLink="/" class="nav-link" >Events</a></li>
                                    <li class="nav-item"><a routerLink="/" class="nav-link" >Newsletter</a></li>
                                    <li class="nav-item"><a routerLink="/" class="nav-link" >Podcasts</a></li>
                                    <li class="nav-item"><a routerLink="/" class="nav-link" >Gallery</a></li> -->
                                </ul>
                            </li>
                            <li class="nav-item"><a routerLink="/careers" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Careers</a></li>     
                            <li class="nav-item contact-button"><a routerLink="/contact" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Contact Us</a></li>     
                    </ul>
                    <div class="side-nav">
                        <a routerLink="/contact">Contact Us</a>
                    </div>
                </div>
            </nav>
                <div class="search-container">
                    <div class="d-flex search-form">
                        <input style="margin-left: 243px;" class="form-control me-2" 
                        [(ngModel)]="search" 
                        (ngModelChange)="onChange()"
                         type="text" placeholder="Search for a lawyer or legal practice" aria-label="Search">
                       <i style="position: relative; right: 40px;" class="icofont-search-1"></i>
                    </div>                  
                </div>
        </div>
        <div *ngIf="attorneyResultIndexes.length >= 1 || postResultIndexes.length >= 1 || practiceResultIndexes.length >= 1" style="color: #161618; list-style-type: none; background-color: white; position: absolute; margin: 0px 15vw; box-shadow: -8px 8px 8px rgba(0, 0, 0, 0.377); min-width: 70vw; top:175px;">
            <div class="search-result-lawyer" style="padding-top:15px;">
                <ul *ngIf="attorneyResultIndexes.length >= 1" style="list-style-type: none;">
                    <h5  style="color:#161618"><a class="search-result" style="text-decoration: none; color:inherit;" routerLink="/meet-our-team">Meet Our Team: </a>{{attorneyResultIndexes.length}} Results</h5 >
                    <li *ngFor="let index of attorneyResultIndexes">
                        <a class="search-result" [routerLink]="['/meet-our-team', lawyers[index].id,lawyers[index].name]" style="text-decoration: none; color:inherit;">{{lawyers[index].name}}</a>
                    </li>
                </ul>
            </div>

            <hr *ngIf="(attorneyResultIndexes.length >= 1 && postResultIndexes.length >= 1) || (practiceResultIndexes.length >= 1 && attorneyResultIndexes.length >= 1)">
  
            <div class="search-result-practice">
               
                <ul *ngIf="practiceResultIndexes.length >= 1" style="list-style-type: none;">
                    <h5 style="color:#161618"><a class="search-result" style="text-decoration: none; color:inherit;" routerLink="/practices">Practices Areas:  </a>{{practiceResultIndexes.length}} Results</h5 >
                    <li *ngFor="let index of practiceResultIndexes">
                        <a class="search-result" [routerLink]="['/practices', practices[index].id, practices[index].name]" style="text-decoration: none; color:inherit;">{{practices[index].contentPreview}}</a>
                    </li>
                </ul>
            </div>
            <hr *ngIf="(postResultIndexes.length >= 1 && practiceResultIndexes.length >= 1)  ">

            <div class="search-result-blog">
                <ul  *ngIf="postResultIndexes.length >= 1" style="list-style-type: none;">
                    <h5  style="color:#161618"><a class="search-result" style="text-decoration: none; color:inherit;" routerLink="/blog">Blogs:  </a>{{postResultIndexes.length}} Results</h5 >
                    <li *ngFor="let index of postResultIndexes" >
                        <a class="search-result" [routerLink]="['/blog', posts[index].id]" style="text-decoration: none; color:inherit;">{{posts[index].contentPreview}}</a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>