import { Practice } from "src/app/Interfaces/interfaces";

export const practiceData: Practice[] = [
    {
        id: 0,
        name: "Litigation",
        img: "../assets/img/litigation.webp",
        content: "Our law firm focuses on litigation, offering clients a unique blend of civil/corporate and commercial litigations. With a team of seasoned litigators, we provide exceptional representation personalized to each client's specific needs. From complex civil disputes, arbitrations, corporate/commercial disputes involving shareholders of corporations, administrative hearings before various regulatory bodies, to personal injury claims, we navigate intricate legal issues, conduct thorough investigations, and craft persuasive arguments to achieve optimal results. With a track record of success, we are dedicated to protecting our clients' rights and achieving justice. When you choose our firm for your litigation needs, you can expect a personalized approach, meticulous preparation, and unwavering dedication to your case. We understand the challenges of litigation and strive to minimize the stress and uncertainty involved. Let us be your trusted partner in the courtroom, guiding you through the complexities of the litigation process and fighting tirelessly to protect your interests. With our tenacity, we are committed to achieving the best possible outcome for you.",
        contentPreview: "Passionate litigators providing personalized representation for civil disputes and personal injury claims. Achieve optimal results with our strategic thinking and unwavering determination.",
        class: "icofont-law-alt-1",
        type: "practice"
    },
    {
        id: 1,
        name: "Commercial Corporate Transactions",
        img: "../assets/img/corporate-ct.webp",
        content: "When it comes to business matters, our law firm is well-versed in commercial and corporate transactions. We understand that each business has unique needs and objectives, and we provide legal solutions that help our clients achieve their commercial goals. With a team member with the C. Dir. (Chartered Director) designation, our law firm engages in drafting and negotiating a variety of simple and complex commercial contracts, handling mergers and acquisitions, and handling due diligence reviews across international boundaries. Our experienced team offers strategic guidance and meticulous attention to detail, ensuring the smooth execution of complex transactions in a dynamic business environment.",
        contentPreview: "Personalized legal solutions for your business goals. From contracts to mergers and acquisitions, we offer strategic guidance and attention to detail.",
        class: "icofont-briefcase-2",
        type: "practice" 
    },
    {
        id: 2,
        name: "Personal Injury",
        img: "../assets/img/personal-injury.webp",
        content: "Our law firm prides itself as the go to law firm for all personal injury cases. We recognize the devastating impact personal injuries can have on individuals and their families. With a compassionate approach, we specialize in handling a wide range of personal injury cases, including slip and fall accidents, medical malpractice claims, motor vehicle accidents, and wrongful death. Our experienced team understands the complexities of these cases and works diligently to protect the rights of our clients. We conduct thorough investigations, collaborate with medical and other experts, to skillfully negotiate or litigate on behalf of our clients to secure the compensation they deserve. With unwavering dedication, we strive to bring justice and help rebuild lives affected by personal injuries.",
        contentPreview: "Compassionate representation for slip and fall accidents, medical malpractice, motor vehicle accidents, and wrongful death cases. We fight for your rights and secure the compensation you deserve.",
        class: "icofont-crutch",
        type: "practice"
    },
    {
        id: 3,
        name: "Immigration",
        img: "../assets/img/immigration.webp",
        content: "At our law firm, we understand the significance of immigration matters and the impact they have on individuals and families. With our deep experience in immigration law, we provide personalised guidance and strategic solutions to help clients navigate the immigration processes. Whether it is obtaining work permits, sponsoring family members, or addressing inadmissibility issues, we are committed to providing compassionate and effective representation, personalized to meet the needs of our clients and ensuring our clients' immigration goals become a reality.",
        contentPreview: "Whether it is obtaining work permits, sponsoring family members, or addressing inadmissibility issues, we are committed to providing compassionate and effective representation, personalized to meet the needs of our clients and ensuring our clients' immigration goals become a reality.",
        class: "icofont-airplane",
        type: "practice"
    },
    {
        id: 4,
        name: "Family Law",
        img: "../assets/img/family-law.webp",
        content: "At our firm, we recognize the sensitive nature of family law matters and the profound impact they have on individuals and families. We approach each case with empathy, offering personalized support and practical solutions to help clients navigate division of matrimonial property. Our goal is to minimize conflict while protecting our clients' rights and advocating for the best interests of the family, helping them move forward with confidence and stability.",
        contentPreview: "Our goal is to minimize conflict while protecting our clients' rights and advocating for the best interests of the family, helping them move forward with confidence and stability.",
        class: "icofont-group-students",
        type: "practice" 
    },
    {
        id: 5,
        name: "Cyberlaw/Intellectual Property",
        img: "../assets/img/cyber-law2.webp",
        content: "In the ever-evolving digital landscape, our firm is increasing being retained for cyberlaw and intellectual property matters. We assist clients in safeguarding their creative works, trademarks, and patents, providing comprehensive legal protection against infringement and unauthorized use. We address issues such as data privacy, cybersecurity breaches, and online defamation. Our innovative approach combines legal knowledge with a keen understanding of technology, allowing us to provide cutting-edge solutions to the unique challenges posed by the digital age.",
        contentPreview: "Comprehensive protection for creative works, trademarks, and patents. We address data privacy, cybersecurity breaches, and online defamation.",
        class: "icofont-laptop-alt",
        type: "practice" 
    },
    {
        id: 6,
        name: "Real Estate",
        img: "../assets/img/real-estate.webp",
        content: "At our law firm, we provide comprehensive legal services for all your real estate needs. Whether you are buying, selling, or refinancing a property, our team is here to guide you through the process with efficiency and expertise. Our team conducts contract reviews, negotiates terms, and ensures all legal requirements are met, protecting your interests throughout the process. Whether you are buying your dream home, selling a property, or refinancing, we strive to deliver a seamless experience.",
        contentPreview: "Efficient legal services for buying, selling, or refinancing property. We protect your interests and optimize your financial position.",
        class: "icofont-home",
        type: "practice" 
    }
];

