import { Component, OnInit } from '@angular/core';
import { Practice, Attorney } from 'src/app/Interfaces/interfaces';
import { practiceData } from '../practice/practiceData';
import { attorneyData } from '../lawyer/lawyerData';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-practice-details',
  templateUrl: './practice-details.component.html',
  styleUrls: ['./practice-details.component.scss']
})
export class PracticeDetailsComponent implements OnInit {
  practice: Practice;
  practices: Practice[];

  lawyers: Attorney[];

  currentPageName: string;
  headerImage: string;
  
  constructor(private route: ActivatedRoute) {
    this.route.paramMap.subscribe(() => {
      this.practice = practiceData[+this.route.snapshot.paramMap.get('id')];
      this.headerImage = this.practice.img;
    });
  }

  ngOnInit(): void {
    let practiceId = this.route.snapshot.paramMap.get('id');
    this.practice = practiceData[practiceId];
    this.practices = practiceData;
    this.lawyers = attorneyData;
    this.currentPageName = this.practice.name;
    this.headerImage = this.practice.img;
  }

}
