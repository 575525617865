import { Attorney } from 'src/app/Interfaces/interfaces';

export let attorneyData: Attorney[] = [
    {
      id: 0,
      type: "lawyer",
      name: "Ugo Ukpabi",
      title: "Lawyer",
      biography: "Dr. Ugo Ukpabi, the Founder and Principal at The Republic Law, possesses a wealth of experience as a highly respected litigator specializing in corporate, commercial, and civil litigation.\n\nWith over two decades of expertise and a comprehensive understanding of different legal markets, including international legal systems, he provides exceptional legal guidance and strong advocacy to his clients. Dr. Ugo Ukpabi has exposure to legal regimes in Nigeria, Geneva, USA, and Canada. Driven by a client-centered approach, he is dedicated to finding creative solutions to complex legal challenges while maintaining the highest ethical standards. Clients who work with Dr. Ukpabi can trust his unwavering commitment to protecting their interests and achieving their desired outcomes.\n\nDr. Ugo Ukpabi has excelled in handling a wide range of litigation matters, appearing before all levels of Alberta courts. His exposure to various legal systems and ability to navigate cross-border issues bring invaluable insights and strategic advantages to his clients. Known for his sensitivity, tact, and deep understanding of their unique circumstances, Dr. Ugo Ukpabi provides exceptional legal representation, making him a highly sought-after litigator in the corporate, commercial, and civil arenas.",
      practiceAreas: [
        "Litigation",
        "Personal Injury",
        "Select Family law Issues, Division of Matrimonial Property comprising of corporate assets",
        "Real Estate",
        "Commercial Corporate Transactions"
      ],
      education: [ 
        "Ph.D. from Osgoode Hall Law, York University, Toronto",
        "MA from Carleton University, Ottawa",
        "LL.M from Obafemi Awolowo University, Ile-Ife (Nigeria)",
        "LL.B. (Hons) from the University of Nigeria",
        "BL. Nigerian Law School"
      ],
      admissions: [
        "Nigeria, 1995",
        "Alberta, 2008"
      ],
      designation: [
        "C.Dir. (2023)",
        "K.C. (King's Counsel) (2022)"
      ],
      membership: [
        "Law Society of Alberta",
        "Canadian Bar Association",
        "Nigerian Bar Association",
        "Calgary Bar Association",
        "International Law Association, Canada"
      ],
      awards: [
        "John Ware Fellow (2022)",
        "Hugo Grotius Visiting Research Scholar at the University of Michigan Law School, Ann Arbor, USA (2004)",
        "Peter Hogg Pre-Doctoral Fellow at Osgoode Hall Law School, York University, Toronto (2003)",
        "Harley D. Hallett Graduate Scholarship to Osgoode Hall Law School (2003)"
      ],
      languages: [
        "English",
        "Igbo"
      ],
      phone: "1-587-319-5916 Ext 101",
      email: "ugoukpabi@republiclaw.ca",
      img: "assets/img/Ugo.webp",
      socialLinks: [
        {
          name:"linkedin",
          link: "https://www.linkedin.com/in/ugo-ukpabi-5080299"
        },
      ],
    },
    {
      id: 1,
      type: "lawyer",
      name: "George Akpan",
      title: "Student-At-Law",
      biography: "Dr. George Akpan is currently a Student-At-Law at The Republic Law, but has legal professional experience in petroleum and foreign investment law spanning over three decades. With extensive experience across various industries and regions, Dr. George Akpan's expertise is highly sought after.\n\nHaving spent over thirteen years at Mobil Producing Nigeria (an ExxonMobil affiliate), Dr. George's contributions were instrumental in his rise to the position of Senior Legal Counsel before departing in 2020. During his tenure at ExxonMobil, he excelled in roles such as Associate Counsel where he oversaw all legal functions within the MPN JV Operations. Dr. George adeptly handled high-stakes negotiations, reviewing multi- billion-dollar procurement and oil and gas contracts. He also played a pivotal role in securing external financing for asset development while ensuring compliance with Nigerian laws governing the company's operations. Notably, Dr. George served as the lead lawyer advising the company on legal and policy matters pertaining to the Nigerian Petroleum Industry Act, which was signed into law in 2021.\n\nIn addition to his remarkable tenure at ExxonMobil, Dr. George's career encompasses prestigious consulting roles with renowned international organizations, including the World Bank and the United Nations Conference on Trade and Development (UNCTAD). He has held esteemed teaching positions at globally recognized universities such as Aoyama Gakuin University in Japan and served as a visiting professor at the United Nations University, Tokyo, Japan. George's extensive publications in reputable journals have further solidified his standing as an authority in petroleum law, foreign investment, international trade, and policy. George’s wealth of experience, profound knowledge, and exceptional track record make him an invaluable asset to clients seeking unparalleled legal guidance in the realms of petroleum and investment law.",
      practiceAreas: [
        "Litigation",
        "Personal Injury",
        "Family Law",
        "Real Estate",
        "Commercial Corporate Transactions",
        "Oil & Gas Law",
        "Foreign Investment Negotiation/Investment Dispute Settlement"
      ],
      education: [ 
        "Ph.D. in Foreign Investment Law and Policy from the National University of Singapore",
        "LL.M in Petroleum Law, Policy, and Economics from the University of Dundee",
        "LL.M from the University of Lagos",
        "LL.B (Hons) from the University of Uyo",
        "Advanced Certificate in Intellectual Property Law from the World Intellectual Property Organization (WIPO)",
        "BL. Nigerian Law School"
      ],
      admissions: [
        "Nigeria, 1990"
      ],
      designation: undefined,
      membership: [
        "Law Society of Alberta",
        "Nigerian Bar Association",
        "Canadian Bar Association",
        "International Law Association, Canada",
        "Calgary Bar Association"
      ],
      awards: [
        "Visiting Fellow, United Nations University, Institute of Advanced Studies",
        "Visiting Scholar, WTO Centre, Aoyama Gakuin University, Shibuya, Tokyo",
        "JSPS-UNU Postdoctoral Fellowship, United Nations University- Institute of Advanced Studies, Japan",
        "University Graduate Fellowship, National University of Singapore",
        "Research Scholarship, National University of Singapore",
        "Laszlo Gombos Award for Outstanding Academic Performance from the Petroleum and Mineral Law Education Trust Fund, London",
        "University of Dundee Research Studentship",
        "Joint Japan/World Bank Scholarship Award",
        "University Prize, Best Graduating Student in Law, University of Uyo"
      ],
      languages: [
        "Anang",
        "Efik",
        "English"
      ],
      phone: "1-587-319-5916 Ext. 103",
      email: "georgeakpan@republiclaw.ca",
      img: "assets/img/george.webp",
      socialLinks: [
        {
          name:"linkedin",
          link: "https://www.linkedin.com/in/george-sampson-akpan-286615113"
        },
      ],
    },
    {
      id: 2,
      type: "lawyer",
      name: "Theodore Oware",
      title: "Student-At-Law",
      biography: "Theodore Oware, is currently a Student-At-Law at The Republic Law. He is dedicated to honing his legal skills and knowledge under the guidance of experienced lawyers. He actively engages in a diverse range of legal matters, including intellectual property, commercial law, and litigation.\n\nTheodore's commitment to providing exceptional legal services stems from his expertise and passion for assisting clients in navigating the complexities of intellectual property law. With an LL.M degree in Intellectual Property and Information Technology from The University of Sussex, coupled with practical experience as a Trainee Trademark Agent, he possesses a comprehensive understanding of trademark law, prosecution, and protection.\n\nAt The Republic Law, Theodore has expanded his practice areas to include litigation, personal injury, and immigration. Theodore has developed skills in crafting persuasive arguments and legal strategies, gaining a deep understanding of courtroom dynamics. Theodore's dedication to seeking justice for those involved in personal injury cases and providing knowledgeable and personalized guidance in immigration matters make him an important asset to The Republic Law. With a client-centred approach, strong advocacy skills, and strategic thinking, Theodore confidently navigates complex legal landscapes, driven by the desire to make a positive impact and help clients achieve their legal goals.",
      practiceAreas: [
        "Litigation",
        "Intellectual Property",
        "Immigration",
        "Personal Injury",
      ],
      education: [ 
        "LL.M in Intellectual Property and Information Technology from The University of Sussex",
        "LL.B. (Hons) with International Relations from The University of Sussex",
        "B.A. in Philosophy from McMaster University",
      ],
      admissions: undefined,
      designation: undefined,
      membership: [
        "Law Society of Alberta",
        "The College of Patent Agents and Trademark Agents",
        "Calgary Bar Association",
        "International Law Association, Canada"
      ],
      awards: undefined,
      languages: [
        "English"
      ],
      phone: "1-587-319-5916 Ext 104",
      email: "TheodoreO@republiclaw.ca",
      img: "assets/img/theodore.webp",
      socialLinks: [
        {
          name:"linkedin",
          link: "https://www.linkedin.com/in/theodore-o-410a36b8"
        },
      ],
    },
];