<div class="page-title-area title-img-blog" style="margin-bottom: 50px;">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="page-title-text">
                <h2>Our Blogs</h2>
            </div>
        </div>
    </div>
</div>

<section class="blog-area">
    <div class="container">
        <div class="blog-slider owl-theme owl-carousel" style="margin-top: 50px;">
            <div *ngFor="let post of posts" class="blog-item">
                <a [routerLink]="['/blog', post.id]">
                    <img srcset="{{post.img}}" alt="Blog">
                </a>

                <div class="blog-inner">
                    <span>{{post.category}}</span>
                    <h3><a [routerLink]="['/blog', post.id]">{{post.title}}</a></h3>
                    <ul>
                        <li><i class="icofont-calendar"></i> {{post.date}}</li>
                        <!-- <li><i class="icofont-user-alt-7"></i>{{post.author}}</li> -->
                    </ul>
                    <p> {{post.contentPreview}}</p>
                    <a class="blog-link" [routerLink]="['/blog', post.id]">Read More <i class="icofont-simple-right"></i></a>
                </div>
        </div>
        </div>
    </div>
</section>