

<div class="lawyer-details">
    <!-- <div class="side-nav">
        <a style="position:fixed; left:50px; top:300px; background-color: #2E568A; opacity: 0.9; border:none;" routerLink="/meet-our-team">Back</a>
    </div> -->
    
    <div class="container">
        <div class="row">
            <div class="col-lg-5 col-lg-1 col-md-6">
                <div class="attor-details-item">
                    <div class="attor-details-name">
                        <h1>{{lawyer.name}}</h1>
                        <h3 style="color: #C8B085; padding-bottom: 0px;">{{lawyer.title}}</h3>
                    </div>
                    <img class="lawyer-img" srcset="{{lawyer.img}}" alt="Details">
                    <div class="attor-details-left">
                        <div class="attor-social-details" style="padding-top: 50px;">
                            <h3>Contact Info</h3>
                            <ul>
                                <li *ngIf="lawyer.phone"><i class="flaticon-call" style="padding-right: 10px;"></i> <a href="tel:{{lawyer.phone}}">{{lawyer.phone}}</a></li>
                                <li *ngIf="lawyer.email"><i class="flaticon-email" style="padding-right: 10px;"></i> <a href="mailto:{{lawyer.email}}">{{lawyer.email}}</a></li>
                                <li *ngFor="let link of lawyer.socialLinks"><i class="icofont-{{link.name}}" style="padding-right: 10px;"></i><a *ngIf="link.link" href={{link.link}} target="_blank">LinkedIn Profile</a></li>
                            </ul>
                        </div>
                    </div>

                    <div class="attor-prev">
                        <ul>
                            <li [ngClass]="currentRouteId !== 0 ? 'nav-prev' : 'disabled'"><a [routerLink]="['/meet-our-team', currentRouteId - 1, previousAttorneyName]">Previous</a></li>
                            <li [ngClass]="currentRouteId !== finalAttorneyId ? 'nav-prev' : 'disabled'"><a [routerLink]="['/meet-our-team', currentRouteId + 1, nextAttorneyName]">Next</a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div *ngIf="lawyer" class="col-lg-7 col-md-6">
                <div class="attor-details-item">
                    <div class="attor-details-right">
                        <div class="attor-details-name">
                            <h1>{{lawyer.name}}</h1>
                            <h3 style="color: #C8B085; padding-bottom: 0px;">{{lawyer.title}}</h3>
                        </div>
                <mat-accordion class="wow animate__animated animate__fadeInUp material-accordion">
                                <mat-expansion-panel class="accordion-panel" data-wow-delay=".3s"  *ngFor="let key of attorneyKeys; let index = index; let isFirst = first" 
                                [expanded]="isFirst">
                                  <mat-expansion-panel-header class="accordion-panel-header">
                                    <mat-panel-title>
                                     {{attorneyKeysTitles[index]}}
                                    </mat-panel-title>
                                  </mat-expansion-panel-header>
                                  <ng-template matExpansionPanelContent>
                                    <div class="accordion-panel-content">
                                        <div *ngIf="isString(lawyer[key])">
                                            <p style="white-space: pre-wrap">{{ lawyer[key] }}</p>
                                          </div>
                                          <div *ngIf="!isString(lawyer[key])">
                                                <p><li *ngFor="let content of lawyer[key]">{{content}}</li></p>
                                          </div>
                                      </div>
                                  </ng-template>
                                </mat-expansion-panel>
                              </mat-accordion>
                </div>
         
            </div>
        </div>
        
    </div>
</div>
    <!-- <div class="container">
        <div class="contact-wrap">
            <form id="contactForm" class="">
                <h2>Get Appointment</h2>
                <div class="row">
                    <div class="col-sm-6 col-lg-6">
                        <div class="form-group">
                            <input type="text" name="name" id="name" class="form-control" placeholder="Your Full Name">
                        </div>
                    </div>

                    <div class="col-sm-6 col-lg-6">
                        <div class="form-group">
                            <input type="email" name="email" id="email" class="form-control" placeholder="Your Email">
                        </div>
                    </div>

                    <div class="col-sm-6 col-lg-6">
                        <div class="form-group">
                            <input type="text" name="phone_number" id="phone_number" class="form-control" placeholder="Your Phone">
                        </div>
                    </div>

                    <div class="col-sm-6 col-lg-6">
                        <div class="form-group">
                            <input type="text" name="msg_subject" id="msg_subject" class="form-control" placeholder="Subject">
                        </div>
                    </div>

                    <div class="col-md-12 col-lg-12">
                        <div class="form-group">
                            <textarea name="message" class="form-control" id="message" cols="30" rows="8" placeholder="Case Description"></textarea>
                        </div>
                    </div>

                    <div class="col-md-12 col-lg-12">
                        <div class="text-center">
                            <button type="submit" class="contact-btn">Submit Case</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div> -->

<!-- <section class="team-area team-area-two">
    <div class="container">
        <div class="section-title text-start">
            <h2>Our Team</h2>
        </div>
        <div class="row">
            <div *ngFor="let lawyer of lawyers;" class="col-sm-6 col-lg-3">
                <div class="image-container wow animate__animated animate__fadeInUp" data-wow-delay=".3s" style="display: flex; height: 350px; overflow: hidden; align-items: flex-start;">
                    <img src={{lawyer.img}} alt="Team" style="flex: 1; object-fit: cover; object-position: center;">
                </div>
                <div class="team-item wow animate__animated animate__fadeInUp" data-wow-delay=".3s" style="background-color: #ecececb4; padding: 0px;" >
                    
                    <div class="lawyer-card" style="padding: 15px;">
                        <h3 style="padding-top: 10px; color: #CBBB7A;"><a style="color: #142233;" [routerLink]="['/meet-our-team', lawyer.id]">{{lawyer.name}}</a></h3>
                        <span style="color: #CBBB7A;">{{lawyer.title}}</span>
                        <hr style="color: #142233;">
                        <ul style="list-style: none; padding: 0; margin: 0;">
                            <li style="padding-bottom: 10px !important;">
                              <i class="icofont-envelope" ></i>
                              <a *ngIf="lawyer.email" href="mailto:{{ lawyer.email }}" style="color: #142233; font-size: 14px;" target="_blank">
                                {{ lawyer.email }}
                              </a>
                            </li>
                            <li style="padding-bottom: 10px !important;">
                              <i class="icofont-phone"></i>
                              <a *ngIf="lawyer.phone" href="tel:{{ lawyer.phone }}" style="color: #142233; font-size: 14px;" target="_blank">
                                {{ lawyer.phone }}
                              </a>
                            </li>
                          </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->