<div class="about-area pt-100">
    <div class="section-title">
        <span>THE REPUBLIC LAW</span>
        <h2>Terms and Conditions</h2>
    </div>

<section class="privacy-area pt-50">
    <div class="container">
        <div class="privacy-item">
            <p>
                The information and articles contained in this web site are general in nature and
                are not intended to provide legal advice. You should not act or fail to act on
                anything based on any of the material contained herein without first consulting
                with a lawyer. Communication to you through this web site is not intended to
                create a solicitor-client relationship. You should consult a lawyer to address the
                specific circumstances of your matter.
                
                Any communication via the internet including e-mail communications are not
                secure and we, therefore, strongly discourage you from communicating
                confidential or sensitive information by e-mail. Any communication to our firm
                via e-mail will not create a solicitor-client relationship and the information sent
                will not be privileged unless you are already a client of our firm. In order to
                become a client of our firm, a formal retainer agreement must be entered into
                by the firm and the client.
                
                The contents of this web site are the property of The Republic Law,
                © Copyright 2023, all rights reserved. The contents cannot be reproduced,
                published, displayed, broadcast or retransmitted without the express written
                consent of Ugo Ukpabi. K.C.
            </p>
        </div>

    </div>
</section>