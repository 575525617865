import { Component, OnInit } from '@angular/core';
import { MailService } from 'src/app/services/mail.service';
import { NgForm } from '@angular/forms';
import { DynamicScriptDirective } from 'src/app/dynamic-script.directive';
import { environment } from '../../../../environments/environment.prod';

@Component({
  selector: 'app-careers',
  templateUrl: './careers.component.html',
  styleUrls: ['./careers.component.scss']
})
export class CareersComponent implements OnInit {

  constructor(
    private mailService: MailService
  ) {}

  private color: string = '';
  showAlert: boolean = false;
  alertMessage: string = '';
  onSubmit: boolean = false;
  formAPI = "fe0e3799-d772-4bc9-8411-aa9e723dc5d6";
  contactFormValues = {
    name: '',
    email: '',
    message: '',
    phone: '',
    subject: '',
  };

  ngOnInit(): void {
  }

  async submitEmail(contactForm: NgForm) {
    this.onSubmit = true;
    let formData: FormData = new FormData();
    
    formData.append('name', this.contactFormValues.name);
    formData.append('email', this.contactFormValues.email);
    formData.append('phone', this.contactFormValues.phone);
    formData.append('body', this.contactFormValues.message);
    
    formData.append('access_key', this.formAPI);
    formData.append('subject', this.contactFormValues.subject);
    formData.append('from_name', 'Contact Notification');
    
    try {
      // -- send email
      const res = await this.mailService.sendEmail(formData);
      if (!res.ok) {
        this.alertMessage = "Your message could not be delivered at this"
        alert(this.alertMessage)
        throw new Error();
      }
      this.alertMessage = "Your message has been sent successfully!"
      alert(this.alertMessage)
      contactForm.reset();
    } catch (err) {

    }
    // -- reset submit and hide alert
    this.onSubmit = false;
    // this.hideAlert();
  }
}
