import { Post } from "src/app/Interfaces/interfaces";
export const postData: Post[] = [
    {
        id: 0,
        title: "Understanding Workers' Compensation Claims in Alberta",
        img: "assets/img/blog-1.webp",
        author: "Tomy Shak",
        date: "1 May 2023",
        category: "Personal Injury Law",
        content: "As an employee in Alberta, you have rights when it comes to workplace injuries. If you're injured on the job, you may be entitled to workers' compensation benefits. Workers' compensation is a form of insurance that provides benefits to employees who are injured or become ill as a result of their work.\n\nThese benefits can include medical care, wage replacement, and other forms of support. To be eligible for workers' compensation benefits, you need to file a claim with the Workers' Compensation Board (WCB) of Alberta. The WCB will investigate your claim and determine whether you're eligible for benefits. There are many factors that can affect your workers' compensation claim, including the nature and severity of your injury, the circumstances surrounding the injury, and your employment status. It's important to work with an experienced workers' compensation lawyer who can help you navigate the claims process and ensure that you receive the benefits you're entitled to.\n\nAt our law firm, we have a team of experienced workers' compensation lawyers who can help you understand your rights and options. Contact us today to schedule a consultation and learn more about how we can assist you with your workers' compensation claim.",
        contentPreview:"Injured on the job? Learn about workers' compensation in Alberta, including how to file a claim and what injuries are covered.",
        tags: ["Personal Injury", "Workplace Injuries", "Workers' Compensation", "Alberta Laws & Regulations"],
        type:"post"
    },
    {
        id: 1,
        title: "The Importance of Estate Planning for Small Business Owners",
        img: "assets/img/blog-2.webp",
        author: "Aikin Ward",
        date: "1 May 2023",
        category: "Business Law",
        content: "As a small business owner, you have worked hard to build your business and create a secure financial future for yourself and your family. However, have you considered what will happen to your business when you pass away? Estate planning is a crucial step in ensuring that your business is protected and can continue to thrive after you're gone.\n\nOne of the main reasons why estate planning is so important for small business owners is that it allows you to determine who will take over the business when you're no longer able to run it. This can include choosing a family member or trusted employee to take over as the new owner, or it can involve selling the business to a third party. Without a clear plan in place, your business could be left in limbo, with no clear direction for its future. Another important aspect of estate planning for small business owners is minimizing taxes and expenses. When you pass away, your estate will be subject to estate taxes, which can be quite substantial.\n\nHowever, with careful planning, you can minimize these taxes and ensure that more of your hard-earned money goes to your family and your business. Finally, estate planning can also help to protect your personal assets. If your business is sued or goes bankrupt, your personal assets could be at risk. By creating a clear plan for your business, you can help to shield your personal assets from these types of risks. In conclusion, estate planning is an essential step for small business owners who want to ensure that their businesses are protected and can continue to thrive after they're gone.\n\nBy creating a clear plan for the future of your business, minimizing taxes and expenses, and protecting your personal assets, you can rest easy knowing that your hard work and dedication will be preserved for years to come.",
        contentPreview:"Protect your small business with estate planning. Learn why it's important, your options, and what to consider when creating a plan.",
        tags: ["Business Law", "Estate Planning", "Small Business", "Tax Planning"],
        type:"post"
    },
    {
        id: 2,
        title: "Divorce and Custody During the Pandemic",
        img: "assets/img/blog-3.webp",
        author: "Sarah Se",
        date: "1 May 2023",
        category: "Family Law",
        content: "Divorce and custody cases can be difficult to navigate under normal circumstances, but the ongoing pandemic has created additional challenges for families in the process of separating. With courts being closed or operating on a limited basis, and restrictions on travel and social gatherings, parents are faced with unique obstacles in settling their divorce and custody matters. But there are steps that can be taken to help ease the process.\n\nOne important step is to maintain clear communication with your ex-spouse, especially when it comes to scheduling and visitation arrangements. With school closures and work-from-home policies, it may be necessary to be flexible and work together to find a schedule that works for everyone. Another key consideration is the safety and well-being of all parties involved. If one parent or child is at high risk for complications from COVID-19, it may be necessary to make adjustments to visitation or custody arrangements.\n\nLegal counsel can also be a valuable resource during this time. An experienced family law lawyer can help you navigate the legal system and ensure that your rights and interests are protected.While the pandemic has added an extra layer of complexity to divorce and custody cases, it's important to remember that you're not alone. With patience, flexibility, and the right support, you can successfully navigate this challenging time and emerge stronger on the other side.",
        contentPreview:"COVID-19 has created new challenges for divorce and custody battles. Learn how to navigate the pandemic's impact on legal options.",
        tags: ["Family Law", "Divorce", "Custody", "Pandemic"],
        type:"post"
    },
];

