import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Post } from 'src/app/Interfaces/interfaces';
import { postData } from '../blog/postData';

@Component({
  selector: 'app-blog-details',
  templateUrl: './blog-details.component.html',
  styleUrls: ['./blog-details.component.scss']
})
export class BlogDetailsComponent implements OnInit {

  post: Post;
  posts: Post[];
  lastPostId;
  currentRouteId;

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.posts = postData;
    this.lastPostId = this.posts[this.posts.length - 1].id;

    this.route.paramMap.subscribe(params => {
      const id = +params.get('id');
      this.post = postData[id];
      this.currentRouteId = id;
    });
  }
};

