import { Component, OnInit } from '@angular/core';
import { attorneyData } from './lawyerData';
import { Attorney } from 'src/app/Interfaces/interfaces';


@Component({
  selector: 'app-lawyer',
  templateUrl: './lawyer.component.html',
  styleUrls: ['./lawyer.component.scss']
})
export class AttorneyComponent implements OnInit {

  lawyer: Attorney;
  lawyers: Attorney[];
  attorneyName: string;
  trackById(object) {
    return object.id;
  }

  constructor() {
    this.lawyers = attorneyData;
  }
 

  ngOnInit(): void {

  }

}

