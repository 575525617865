<div class="about-area">
    <div class="section-title">
        <span>OUR STORY</span>
        <h2>The Republic Law</h2>
    </div>
    <div class="container">
        <div class="row about-content" style="background-color: rgba(209, 209, 209, 0.425); padding: 35px;">
            <div class="col-lg-4">
                    <img class="about-img" srcset="/assets/img/about-us1.webp" priority>
            </div>

            <div class="col-lg-8">
                <div class="about-item">
                    <div class="about-information" style="padding-left: 25px;">
                        <h1 class="about-title">Introducing The Republic Law: <span>Seeking Justice For All</span></h1>
                        <br/>
                        <p>At The Republic Law, we take pride in being your trusted legal partner. With a demonstrated
                            commitment to excellence, our experienced lawyers deliver personalized solutions to meet your
                            specific needs. From personal injury and family to business and immigration matters, we are
                            dedicated to providing you with the highest quality legal representation.</p>
                        <p>Our firm's foundation is built on integrity, professionalism, and a client-centred approach. We
                            understand that every case is unique, and we work closely with you to understand your goals
                            and develop a strategic plan to achieve the best possible outcome. With our expertise and
                            unwavering dedication, you can trust us to protect your rights, navigate complex legal
                            challenges, and champion your interests.</p>
                        <p>Experience the difference of working with The Republic Law, where we combine our in-depth
                            knowledge, skilled advocacy, and personalised attention to provide you with exceptional legal
                            services. Contact us today to discuss your legal needs and embark on a successful legal journey
                            with our trusted team by your side.</p> 
                    </div>
                </div>
            </div>
        </div>
        <div class="row" style="margin-top: 60px;">
            <div class="col" style="display:flex; align-items: center; padding-right: 40px;border: #CBBB7A 3px solid; padding: 35px; display: flex; align-items: center;">
                <div class="about-content" >
                    <h2>About The Firm</h2>
                    <p>At our law firm, we have a unique and special purpose: to empower individuals, protect rights, expand happiness, and inspire justice. Justice is not just a lofty ideal, but we strive to excavate it, one project at a time, one mandate at a time, Justice in Motion. We do that through our unwavering commitment to integrity, virtue, compassion, ethics and excellence as we seek to make a meaningful impact on the lives of our clients and society as a whole. With a client-centred approach, we listen attentively, understand your unique challenges, and tailor our strategies and solutions to achieve the best outcomes. We believe in the power of the Law to bring about positive change and to create a more just and equitable world. By embracing innovation, fostering collaboration, and giving back to our community, we strive to be agents of positive transformation, providing comprehensive and holistic solutions to complex legal challenges.</p>
                    <p>When you choose our law firm, you choose a partner for navigating legal complexities. We are dedicated to building long-term relationships with our clients, earning their trust, and becoming their trusted advisors for life. Together, we can create a world where justice prevails, and individuals are empowered to thrive.</p>
                </div>
            </div>
        </div>
        <div class="row about-values" style="margin-top: 40px; background-color: rgba(209, 209, 209, 0.425); padding: 35px;">
            <h2>Our Core Values</h2>
            <ol style="margin-left: 20px; line-height: 40px;">
                <li style="margin-top: 15px"><strong style="color: #CBBB7A; margin-left: 10px; font-size: 20px;"> Client-Centered Approach</strong><br>Our clients are at the heart of everything we do.
                    We prioritise listening attentively, understanding their unique needs, and
                    providing personalized solutions that always emphasise their best interests.
                    With empathy, we acknowledge the emotional and personal challenges clients
                    face, offering support, understanding, and guidance throughout their legal
                    journey. We aim to cultivate long-lasting relationships based on trust,
                    reliability, and mutual respect, serving as their trusted legal advisors beyond
                    individual cases.</li>
                <li style="margin-top: 15px"><strong style="color: #CBBB7A; margin-left: 10px; font-size: 20px;"> Integrity</strong><br>We uphold the highest standards of honesty, transparency, and
                    ethical conduct in all aspects of our practice. Our commitment to integrity
                    ensures that we consistently earn and maintain our clients' trust. We believe
                    that trust is the foundation of a successful lawyer-client relationship, and we
                    strive to act with integrity in every interaction and decision we make.</li>
                <li style="margin-top: 15px"><strong style="color: #CBBB7A; margin-left: 10px; font-size: 20px;"> Excellence</strong><br>We are dedicated to achieving excellence in every facet of our
                    work. Through continuous professional development, ongoing learning, and
                    staying abreast of the latest legal knowledge and development, we deliver
                    top-quality service to our clients. We embrace technological advancements
                    and innovative approaches to enhance the efficiency and effectiveness of our
                    legal services. By embracing innovation, we continually find new ways to meet
                    and exceed our clients' expectations.</li>
                <li style="margin-top: 15px"><strong style="color: #CBBB7A; margin-left: 10px; font-size: 20px;"> Collaboration</strong><br>We foster a collaborative environment, both internally within
                    our firm and externally with clients and other professionals. By leveraging the
                    collective knowledge and expertise of our team, we provide comprehensive
                    and innovative solutions to our clients legal challenges. We believe in giving
                    back to the communities we serve, actively participating in pro bono work,
                    community initiatives, and making charitable contributions. We strive to make
                    a positive impact beyond the walls of our firm.</li>
                <li style="margin-top: 15px"><strong style="color: #CBBB7A; margin-left: 10px; font-size: 20px;"> Advocacy for Justice</strong><br>We are passionate advocates for justice and equality.
                    We believe that a society unmoored from the tenets of justice for the greatest
                    number of its citizens (and non-citizens alike) is hollow, and ultimately, is
                    bound to unravel. We actively seek to protect the rights of our clients and
                    work towards a fair and just legal system that serves the needs of all
                    individuals. We are committed to promoting access to justice and ensuring
                    that everyone receives fair representation regardless of their background or
                    circumstances.</li>
            </ol>
  
        </div>
    </div>
</div>