import { Component, OnInit } from '@angular/core';
import { Post } from 'src/app/Interfaces/interfaces';
import { postData } from './postData';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss']
})
export class BlogComponent implements OnInit {
  posts:Post[];
  constructor() { }

  ngOnInit(): void {
    this.posts = postData;
  }

}
