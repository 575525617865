<div class="banner-area banner-area-two banner-img-one">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">     
                <div class="row align-items-center">
                    <div class="col-lg-12">
                        <div class="banner-item">
                            <div class="banner-left">
                                <h1>The Republic Law</h1>
                                <p>We strive to provide legal solutions that empower our clients and promote the greater good.</p>
                                <a routerLink="/about">LEARN MORE<i class="icofont-arrow-right"></i></a>
                            </div>
                        </div>
                    </div>
                </div>        
            </div>
        </div>
    </div>
</div>
<div class="help-area help-area-two">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="help-item help-left hide">
                    <img class="hide" srcset="assets/img/diverse.webp" alt="">
                </div>
            </div>

            <div class="col-lg-6">
                <div class="help-item">
                    <div class="help-right">
                        <h2>Our Mission</h2>
                        <p>The Republic Law is a business and litigation law firm serving the people. We are a dedicated team that believes everyone deserves access to high-quality legal services, regardless of their background or circumstances.</p>
                        
                        <h5>- Ugo Ukpabi</h5>

                        <span style="padding-top:-80px; font-weight: 500;font-size: 15px; color: rgba(53, 53, 53, 0.507);">Barrister, Solicitor & Notary Public</span>
                        <a style="margin-top:30px" class="cmn-btn" routerLink="/about">Learn More</a>
                    </div>
                </div>
            </div>
        </div>
        <div class="help-shape">
        </div>
    </div>
</div>

<section class="practice-area practice-area-two pt-0">
    <div class="container">
        <div style="margin-bottom:-30px !important;" class="section-title">
            <span>HOW CAN WE HELP YOU</span>
            <h2>Practice Areas</h2>
        </div>
        <section class="practice-area practice-area-three pt-100">
            <div class="container">
                <div class="row">
                    <div *ngFor="let practice of practices" [ngClass]="(practice.id == 0 || practice.id == 1) ? 'col-sm-6 col-lg-6' : 'col-sm-6 col-lg-4'">
                        <div class="practice-item">
                            <div class="practice-icon">
                                <i class="{{practice.class}}"></i>
                            </div>
                            <h3>{{practice.name}}</h3>
                            <p>{{practice.contentPreview}}</p>
                            <a [routerLink]="['/practices', practice.id, practice.name]">Read More</a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
   
</section>
<!-- <section class="blog-area">
    <div class="container">
        <div class="section-title">
            <h2>Our Latest Blogs</h2>
            <span>Empowering you with knowledge: Our blog is dedicated to providing you with the legal insights you need to make informed decisions.</span>
        </div>
     
        <div class="blog-slider owl-theme owl-carousel">
            <div *ngFor="let post of posts" class="blog-item">
                <a [routerLink]="['/blog', post.id]">
                    <img srcset="{{post.img}}" alt="Blog">
                </a>

                <div class="blog-inner">
                    <span>{{post.category}}</span>
                    <h3><a [routerLink]="['/blog', post.id]">{{post.title}}</a></h3>
                    <ul>
                        <li><i class="icofont-calendar"></i> {{post.date}}</li>
                        <li><i class="icofont-user-alt-7"></i>{{post.author}}</li>
                    </ul>
                    <p> {{post.contentPreview}}</p>
                    <a class="blog-link" [routerLink]="['/blog', post.id]">Read More <i class="icofont-simple-right"></i></a>
                </div>
        </div>
        </div>
    </div>
</section> -->
<section>
    <div class="container">
        <div class="row faq-wrap">
            <div class="col-lg-12">
                <div class="faq-head">
                    <h2>FAQ's</h2>
                </div>
        
                <div class="faq-item">
                    <ul class="accordion">
                        <li class="wow animate__animated animate__fadeInUp" data-wow-delay=".3s">
                            <a>What are your fees for legal services?</a>
                            <p>Our fee structure varies depending on the complexity and type of legal service
                                required. We offer transparent and competitive pricing, and we strive to provide 
                                cost-effective solutions for our clients. To get a detailed understanding of our fees, we
                                recommend scheduling a consultation with one of our lawyers.</p>
                        </li>
                        
                        <li class="wow animate__animated animate__fadeInUp" data-wow-delay=".4s">
                            <a>How do you accept payment for your services? </a>
                            <p>We understand the importance of convenience for our clients, which is why we offer
                                various payment options. You can make payments through traditional methods such
                                as cash, checks, or credit/debit cards. Additionally, we have an online payment
                                system where you can conveniently settle your invoices by following a secure
                                payment link.</p>
                        </li>
        
                        <li class="wow animate__animated animate__fadeInUp" data-wow-delay=".5s">
                            <a>What are you core practice areas?</a>
                            <p>Our law firm offers a comprehensive range of legal services to meet our clients' needs. Some of our key practice areas include:<br/><br/>
                                <li><strong>Civil, Corporate /Commercial Litigation:</strong> 
                                    Representing clients in civil disputes,
                                    such as contract disputes, property disputes, and personal injury claims. We
                                    also appear for clients before disciplinary tribunals like the CPA, The CPSA
                                    and the Alberta Human Rights Tribunals.</li>
                                <li><strong>Family Law:</strong> Handling matters related to divorce and division of matrimonial property.</li>
                                <li><strong>Intellectual Property Law:</strong> Assisting clients with copyright, trademark, patent, and trade secret matters.</li>
                                    <li><strong>Real Estate Law:</strong> Advising clients on property transactions, leases, zoning, land use, and real estate disputes.</li>
                                <li><strong>Corporate Matters:</strong> Drafting agreements, mergers and acquisitions and due diligence across international borders.</li>
                                </p>
                        </li>
        
                        <li class="wow animate__animated animate__fadeInUp" data-wow-delay=".6s">
                            <a>Why should I choose The Republic Law? </a>
                            <p>There are several reasons why you would choose The Republic Law:<br/><br/>
                                <li><strong>Experience and Expertise:</strong> Our team of lawyers have extensive experience in their respective areas of practice, ensuring that you receive
                                    high-quality legal service.</li>
                                <li><strong>Client-Centered Approach:</strong> We prioritize our clients' needs and work closely
                                    with them, providing personalized solutions.</li>
                                <li><strong>Results-Driven:</strong>: We are dedicated to achieving favourable outcomes for our
                                    clients and will diligently advocate for their rights and interests.</li>
                                <li><strong>Transparent Communication:</strong> We believe in open and transparent
                                    communication, keeping our clients informed at every stage of their legal
                                    matters.</li>
                                <li><strong>Integrity:</strong> Much as we drive for success, it is critically important for us to do so
                                    within the bounds of our professional ethics. Ethics underpins and guides our
                                    practice. </li>
                                <li><strong>Commitment to Excellence:</strong> Our firm upholds the highest standards of
                                    professionalism, ethics and integrity, striving to exceed clients expectations.</li>
                                </p>
                        </li>
        
                        <li class="wow animate__animated animate__fadeInUp" data-wow-delay=".7s">
                            <a>What if I cannot afford legal representation?</a>
                            <p> We understand that legal costs can be a concern for some clients. Our firm is
                                committed to ensuring access to justice for all. In certain cases, we offer flexible
                                payment plans or alternative fee arrangements. Additionally, we can provide
                                guidance on potential sources of legal aid or pro bono services available in your
                                area.</p>
                        </li>

                        <li class="wow animate__animated animate__fadeInUp" data-wow-delay=".7s">
                            <a>What is your approach to resolving legal disputes?</a>
                            <p> At our law firm, we prioritize finding the most efficient and effective solutions for our
                                clients. We strive to resolve legal disputes through negotiation, mediation, arbitration
                                and settlement discussions whenever possible, as these approaches can often save
                                time, money, and emotional stress. However, if litigation becomes necessary, we
                                have skilled lawyers who will vigorously advocate for your rights in the courtroom.</p>
                        </li>

                        <li class="wow animate__animated animate__fadeInUp" data-wow-delay=".7s">
                            <a>Do you handle cases on a contingency fee basis? </a>
                            <p> Yes, in certain types of cases, we offer representation on a contingency fee basis.
                                This means that our fees are contingent upon successfully recovering compensation
                                on your behalf. We will discuss the details of our fee structure during the initial
                                consultation and ensure that you have a clear understanding of the terms before
                                proceeding.</p>
                        </li>

                        <li class="wow animate__animated animate__fadeInUp" data-wow-delay=".7s">
                            <a>How do I get started with your law firm?</a>
                            <p> To get started, simply reach out to our firm via phone, email, or through the contact
                                form on our website. Our friendly staff will assist you in scheduling an initial
                                consultation with one of our lawyers. During the consultation, we will discuss your
                                legal needs, provide an overview of our services, and determine the best course of
                                action for you.</p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>
