<div class="title-img-careers">
        <div class="container-fluid" style="background-color: #1b1b1bb4; padding-top: 280px; padding-bottom: 110px;">
            <div class="section-title">
                <h2 style="color: white;">Join The Republic Law</h2>
                <p style="color: #ABABAB; font-size: 15px; padding-top: 15px;">At The Republic Law, we are continually seeking Students-At-Law and skilled Lawyers to join our dynamic team. <br><b>Please send your resume and cover letter to </b><a href="mailto:Info@republiclaw.ca">Info@republiclaw.ca</a><b> or fill out the form below. </b></p>
            </div>
            <form action="https://api.web3forms.com/submit" method="POST" #contactForm="ngForm" (ngSubmit)="submitEmail(contactForm)">
                <input type="hidden" name="access_key" value="formAPI">
                    <div class="row contact-wrap">
                        <div class="col-sm-6 col-lg-6">
                            <div class="form-group">
                                <input
                                    name="name"
                                    [(ngModel)]="contactFormValues.name"
                                    #name="ngModel"
                                    type="text"
                                    placeholder="Your Full Name"
                                    class="form-control"
                                    required
                                    minlength="2"
                                    />
                                    <!-- input error message -->
                                    <p
                                    class="ml-1 text-red-400 text-sm"
                                    *ngIf="name.errors && name.touched && name.dirty"
                                    >
                                    Please enter your name
                                    </p>
                        
                            </div>
                        </div>

                        <div class="col-sm-6 col-lg-6">
                            <div class="form-group">
                                <input
                                    name="email"
                                    [(ngModel)]="contactFormValues.email"
                                    #email="ngModel"
                                    type="email"
                                    placeholder="Your Email"
                                    class="form-control"
                                    required
                                    pattern="[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?"
                                    />
                                    <!-- input error message -->
                                    <p
                                    class="ml-1 text-red-400 text-sm"
                                    *ngIf="email.errors && email.touched && email.dirty"
                                    > 
                                    Please enter your email
                                    </p>
                            </div>
                        </div>

                        <div class="col-sm-6 col-lg-6">
                            <div class="form-group">
                                <input 
                                type="text" 
                                name="phone"
                                id="phone" 
                                required data-error="Please enter a valid phone number" 
                                class="form-control" 
                                placeholder="Your Phone Number"
                                [(ngModel)]="contactFormValues.phone"
                                #phone="ngModel"
                                minlength="10" 
                                >
                            </div>
                        </div>
                        <div class="col-sm-6 col-lg-6">
                            <div class="form-group">
                                <input
                                type="text" 
                                name="msg_subject" 
                                id="msg_subject" 
                                class="form-control" 
                                required 
                                data-error="Please enter your subject" 
                                placeholder="Subject"
                                [(ngModel)]="contactFormValues.subject"
                                #subject="ngModel"
                                minlength="6" 
                                >
                                <!-- *ngIf="email.errors && email.touched && email.dirty" -->
                            </div>
                        </div>

                         <div class="col-md-12 col-lg-12">
                            <div class="form-group">
                                <textarea 
                                    name="message" 
                                    class="form-control"  
                                    [(ngModel)]="contactFormValues.message"
                                    #body="ngModel" 
                                    id="message" 
                                    cols="30" 
                                    rows="8" 
                                    required
                                    minlength="20" 
                                    data-error="Please write your message" 
                                    placeholder="Tell us about yourself">
                                </textarea>
                            </div>
                        </div> 
                        <!-- <div style="display: flex; justify-content: center; margin: 0px 0 20px 0" class="col-md-12 col-lg-12">
                            <div class="h-captcha" data-captcha="true"></div>
                        </div> -->
                        <input type="checkbox" name="botcheck" class="hidden" style="display: none;">
                        
                        <div class="col-md-12 col-lg-12">
                            <div class="text-center">
                                <button
                 
                                [disabled]="contactForm.invalid || onSubmit"
                                class="contact-btn"
                              >
                                <ng-container >
                                    Submit
                                </ng-container>
                                <!-- <ng-template #submittingEmail>
                                </ng-template> -->
                              </button>
                            </div>
                        </div>
                    </div>
            </form>
        </div>
    </div>
<!-- </div> -->

<div class="container-fluid" style="background-color: #ECECEC; padding: 30px;">
    <div class="row">
        <div class="col-4" style="display: flex; flex-direction: column; justify-content: center;">
            <h2 style="padding: 20px 30px; color: #161618;">Contact Us</h2>
            <ul style="list-style:none; padding: 10px 30px; font-size: 20px; color: #161618;">
                <li style="padding-bottom: 20px;"><i class="icofont-smart-phone" style="color: #CBBB7A; padding-right: 10px;"></i><a>  Phone: 587-319-5916</a></li>
                <li style="padding-bottom: 20px;"><i class="icofont-fax" style="color: #CBBB7A; padding-right: 10px;"></i><a>  Fax: 1-877-659-9376</a></li>
                <li style="padding-bottom: 20px;"><i class="icofont-email" style="color: #CBBB7A; padding-right: 10px;"></i><a>  Email: info@republiclaw.ca</a></li>
                <li style="padding-bottom: 20px;"><i class="icofont-location-pin" style="color: #CBBB7A; padding-right: 10px;"></i><a>  Address: Suite #305, 609-14th Street NW<br/>Calgary, AB T2N 2A1</a></li>
            </ul>
        </div>
        <div class="col-8">
            <div class="map-area">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2507.7978787667485!2d-114.0950067!3d51.05681980000001!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x53716feaf904e0d9%3A0x4e3f37d81a0a02a!2s609%2014%20St%20NW%20Suite%20305%2C%20Calgary%2C%20AB%20T2N%202A1!5e0!3m2!1sen!2sca!4v1682647666523!5m2!1sen!2sca" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
        </div>
    </div>
</div>

<div appDynamicScript>
	<!-- most tags work, but Angular strips script tags, so don't try it. -->
	<div
		src="https://web3forms.com/client/script.js"
        async
        defer
	>
	</div>
</div>