<div class="page-title-area title-img-our-team image-container" style="background-attachment: fixed; background-position: center top 25%;">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="page-title-text">
                <h2>Our Team</h2>
            </div>
        </div>
    </div>
</div>

<section class="team-area team-area-two pt-100">
    <div class="container">                
        <div class="row">
            <div *ngFor="let lawyer of lawyers; trackBy: trackById(lawyers)" class="col-sm-6 col-lg-4 wow animate__animated animate__fadeInUp" data-wow-delay=".3s">
                <div [routerLink]="['/meet-our-team', lawyer.id, lawyer.name]" class="image-container" style="display: flex; height: 350px; overflow: hidden; align-items: flex-start;">
                    <img srcset={{lawyer.img}} alt="Team" style="flex: 1; object-fit: cover; object-position: center;">
                </div>
                <div class="team-item" style="background-color: #ecececb4; padding: 0px;" >
                    
                    <div class="lawyer-card" style="padding: 15px;">
                        <h3 [routerLink]="['/meet-our-team', lawyer.id, lawyer.name]" style="padding-top: 10px; color: #CBBB7A;"><a style="color: #142233;" >{{lawyer.name}}</a></h3>
                        <span [routerLink]="['/meet-our-team', lawyer.id, lawyer.name]" style="color: #CBBB7A;">{{lawyer.title}}</span>
                        <hr style="color: #142233;">
                        <ul style="list-style: none; padding: 0; margin: 0;">
                            <li style="padding-bottom: 10px !important;">
                              <i class="icofont-envelope" ></i>
                              <a *ngIf="lawyer.email" href="mailto:{{ lawyer.email }}" style="color: #142233; font-size: 14px;" target="_blank">
                                {{ lawyer.email }}
                              </a>
                            </li>
                            <li style="padding-bottom: 10px !important;">
                              <i class="icofont-phone"></i>
                              <a *ngIf="lawyer.phone" href="tel:{{ lawyer.phone }}" style="color: #142233; font-size: 14px;" target="_blank">
                                {{ lawyer.phone }}
                              </a>
                            </li>
                          </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>