<div class="page-title-area-practices title-img-our-practice">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="page-title-text">
                <h2>Practices Areas</h2>
            </div>
        </div>
    </div>
</div>

<section class="practice-area practice-area-three pt-100">
    <div class="container">
        <div class="row">
            <div *ngFor="let practice of practices" [ngClass]="(practice.id == 0 || practice.id == 1) ? 'col-sm-6 col-lg-6' : 'col-sm-6 col-lg-4'">
                <div class="practice-item">
                    <div class="practice-icon">
                        <i style="padding-top: 10px;" class="{{practice.class}}"></i>
                    </div>
                    <h3>{{practice.name}}</h3>
                    <p>{{practice.contentPreview}}</p>
                    <a [routerLink]="['/practices', practice.id, practice.name]">Read More</a>
                </div>
            </div>
        </div>
    </div>
</section>