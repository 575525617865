import { Component, OnInit } from '@angular/core';
import { postData } from '../blog/postData';
import { practiceData } from '../practice/practiceData';
import { attorneyData } from '../lawyer/lawyerData';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  posts = postData;
  lawyers = attorneyData;
  practices = practiceData;

  attorneyNames;
  postContentPreviews;
  practiceContentPreviews;

  attorneyResultIndexes = [];
  postResultIndexes = [];
  practiceResultIndexes = [];

  queryTypes = [this.posts, this.lawyers, this.practices];

  search: string = '';
  constructor() { }

  ngOnInit(): void {
    this.getContentPreviews();
  }

  onChange() {
    if (this.search.length < 3) {
      this.attorneyResultIndexes = [];
      this.postResultIndexes = [];
      this.practiceResultIndexes = [];
    }

    if (this.search.length >= 3) {
      this.getAllQueryResults();
    }
  }

  getAllQueryResults() {
    // queryTypes is all the data types that will be filtered through
    
    this.queryTypes.forEach((queryType) => {
      console.log(queryType)
      this.getQueryResults(queryType);
    });
  }

  getQueryResults(queryType) {
    // loop through all
    queryType.forEach((object) => {
      let includesSearchString = object.name?.toLowerCase().includes(this.search.toLowerCase());

      // if name doesn't exist use contentPreview
      if (!object.name) {
        includesSearchString = object.contentPreview.toLowerCase().includes(this.search.toLowerCase());
      }
      
      // if we match the search string and we didn't already match these indexes add it to the respective result arrays

      switch(object.type) {
        case 'lawyer':
          if (includesSearchString && !this.attorneyResultIndexes.includes(object.id) ) {
            this.attorneyResultIndexes.push(object.id);
          }
          break;
        case 'practice':
          if (includesSearchString && !this.practiceResultIndexes.includes(object.id)) {
            this.practiceResultIndexes.push(object.id);
          }
          break;
        case 'post':
          if (includesSearchString && !this.postResultIndexes.includes(object.id)) {
            this.postResultIndexes.push(object.id);
          }
          break;
      }
    
    });

  }

  getContentPreviews() {
    this.attorneyNames = this.getQueryStrings(this.lawyers);
    this.postContentPreviews = this.getQueryStrings(this.posts);
    this.practiceContentPreviews = this.getQueryStrings(this.practices);
  }

  getQueryStrings(data) {
   let array = data.map((object, index) => {
      let arrayOfStrings;

      if (object.contentPreview) {
        arrayOfStrings = object.contentPreview.split(" ");
      } else {
        arrayOfStrings = object.name.split(" ");
      }

      return arrayOfStrings;
    });

    return array;
  }
  
  

}
