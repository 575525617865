import { Component, OnInit } from '@angular/core';
import { practiceData } from './practiceData';
import { Practice } from 'src/app/Interfaces/interfaces';

@Component({
  selector: 'app-practice',
  templateUrl: './practice.component.html',
  styleUrls: ['./practice.component.scss']
})
export class PracticeComponent implements OnInit {
  practices: Practice[];
  constructor() { }

  ngOnInit(): void {
    this.practices = practiceData;
  }

}
